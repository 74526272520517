import { getCookie, setCookie } from "init/cookie";

const KEY = "comparisonListings";
const MAX_LENGTH = 4;
export default class {
  addListing(id) {
    const state = this.state();
    let removed = [];

    if (!state.includes(id)) {
      let newState = state.concat(id);
      if (newState.length > MAX_LENGTH) {
        const keepIndex = newState.length - MAX_LENGTH;
        removed = newState.slice(0, keepIndex);
        newState = newState.slice(keepIndex);
      }

      this.updateState(newState);
    }

    // Return items rejected from the list so they can be unchecked
    return removed;
  }

  removeListing(id) {
    const state = this.state();

    if (state.includes(id)) {
      const newState = state.filter(a => a !== id);

      this.updateState(newState);
    }
  }

  state() {
    const json = getCookie(KEY);

    if (json) {
      const state = JSON.parse(json);

      if (state) {
        return state;
      }
    }

    return [];
  }

  updateState(newState) {
    const json = JSON.stringify(newState);
    setCookie(KEY, json, 7);
  }
}
