import { Controller } from "stimulus";
import Glide from "@glidejs/glide";
import Comparison from "../comparison_modal/model";

export default class extends Controller {
  static targets = ["slider"];

  connect() {
    this.images = this.element.querySelectorAll(".glide__slides [data-src]");
    this.count = this.images.length;

    if (this.sliderTarget) {
      this.mount();
      window.addEventListener("mount", this.remount);
    }

    this.handleChecked();
  };

  handleChecked = () => {
    if (this.element.querySelector(".compare-checkbox").checked) {
      // This needs a slight delay for the event handler to be registered
      this.delay(50).then(() => this.compare());
    }
  };

  nextSlide() {
    this.slider.go(">");
  }

  prevSlide() {
    this.slider.go("<");
  }

  remount = () => this.slider.update();

  mount = () => {
    // Slider Options Object
    const sliderOptions = {
      animationDuration: 350,
      type: "carousel",
      perTouch: 1,
      touchAngle: 60,
      throttle: 10
    };
    this.slider = new Glide(this.sliderTarget, sliderOptions);
    this.slider.mount();
  };

  // Async/(Implied)Await "Pause" function.
  delay = async ms => new Promise(res => setTimeout(res, ms));

  compare = async () => {
    // Event bubbles up to listing grid to control button
    const event = document.createEvent("CustomEvent");
    event.initCustomEvent("compare-update", true, true, null);
    this.element.dispatchEvent(event);
  };

  comparison = () => {
    this.comparisonInstance = this.comparisonInstance || new Comparison();
    return this.comparisonInstance;
  };
}
